import React from 'react';


// BARRA ESQUERDA
const LeftSidebar = () => (
  <div className="w-full md:w-1/4 border-r-2">
      <div className="w-full h-24 flex justify-center md:justify-start">
          <img className="object-cover h-full w-40 md:w-80" src="/images/2.svg" alt="Logo Agrobrazil Menu" />
      </div>
      <ul className="list-none ml-10 md:ml-8 space-y-5 py-10 text-lg md:text-xl">
          <li className="flex items-center space-x-2">
              <img className="w-5 h-5" src="/images/home.svg" alt="" />
              <span className="hover:text-cyan-500 cursor-pointer">Página Inicial</span>
          </li>
          <li className="flex items-center space-x-2">
              <img className="w-5 h-5" src="/images/classificados.svg" alt="" />
              <span className="hover:text-cyan-500 cursor-pointer">Classificados</span>
          </li>
          <li className="flex items-center space-x-2">
              <img className="w-5 h-5" src="/images/cotacao.svg" alt="" />
              <span className="hover:text-cyan-500 cursor-pointer">Cotações</span>
          </li>
          <li className="flex items-center space-x-2">
              <img className="w-5 h-5" src="/images/escalas2.svg" alt="" />
              <span className="hover:text-cyan-500 cursor-pointer">Escalas</span>
          </li>
          <li className="flex items-center space-x-2">
              <img className="w-5 h-5" src="/images/mercadofuturo.svg" alt="" />
              <span className="hover:text-cyan-500 cursor-pointer">Mercado Futuro</span>
          </li>
          <li className="flex items-center space-x-2">
              <img className="w-5 h-5" src="/images/news.svg" alt="" />
              <span className="hover:text-cyan-500 cursor-pointer">Notícias</span>
          </li>
          <li className="flex items-center space-x-2">
              <img className="w-5 h-5" src="/images/relatorio.svg" alt="" />
              <span className="hover:text-cyan-500 cursor-pointer">Relatórios</span>
          </li>
      </ul>
  </div>
);

// BARRA CENTRAL
const CentralContent = () => (
  <div className="w-full md:w-2/4 border-r-2">
      <nav className="flex items-center justify-center mb-4 border-b-2">
          <ul className="flex flex-wrap justify-center space-x-4 md:space-x-20 py-4 md:py-9 font-medium text-lg md:text-xl bg-slate-50">
              <li className="relative text-blue-900 px-2 md:px-4 py-2 bottom-bg cursor-pointer">
                  <span className="hover:text-blue-900 cursor-pointer">Negócios Realizados</span>
              </li>
              <li className="relative text-blue-900 px-2 md:px-4 py-2 bottom-bg cursor-pointer">
                  <span className="hover:text-blue-900 cursor-pointer">Notícias</span>
              </li>
              <li className="relative text-blue-900 px-2 md:px-4 py-2 bottom-bg cursor-pointer">
                  <span className="hover:text-blue-900 cursor-pointer">Classificados</span>
              </li>
              <li className="relative text-blue-900 px-2 md:px-4 py-2 bottom-bg cursor-pointer">
                  <span className="hover:text-blue-900 cursor-pointer">Ranking Frigorífico</span>
              </li>
          </ul>
      </nav>
      <h2 className="text-xl md:text-2xl font-bold mb-4 text-center">Conteúdo Central</h2>
      <div className="grid grid-cols-1 gap-4">
          <div className="bg-gray-100 p-4 rounded-lg shadow-md mx-2">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Card 1</h3>
              <p>Conteúdo do card 1.</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md mx-2">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Card 2</h3>
              <p>Conteúdo do card 2.</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md mx-2">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Card 3</h3>
              <p>Conteúdo do card 3.</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md mx-2">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Card 4</h3>
              <p>Conteúdo do card 4.</p>
          </div>
      </div>
  </div>
);

// BARRA DIREITA
const RightSidebar = () => (
  <div className="w-full md:w-1/4 bg-white p-6 rounded-lg shadow-lg m-4">
      <h2 className="text-xl md:text-2xl font-bold mb-4">Informações Adicionais</h2>
      <p>Coloque aqui qualquer outra informação ou conteúdo adicional.</p>
  </div>
);

const App = () => (
  <div className="flex min-h-screen">
      <LeftSidebar />
      <CentralContent />
      <RightSidebar />
  </div>
);

export default App;